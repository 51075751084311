@import "~normalize.css/normalize.css";
@import "partials/_variables.scss";
@import "partials/_accordion.scss";
@import "partials/_slider.scss";
@import "partials/_columns.scss";
@import "partials/_buttons.scss";
@import "partials/_forms.scss";
@import "partials/_messages.scss";

@import "partials/_cart.scss";

@import url("https://use.typekit.net/jfh4egc.css");

/* AQUASURE WEBSITE 2013 by Digital Bridge */
body {
    font: normal 12px/1.4 museo-sans, Helvetica, sans-serif;
    color: #353535;
    background: #36414e;
    -webkit-font-smoothing: subpixel-antialiased;
}

/*RESET*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
q,
small,
strong,
sub,
sup,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
    border: 0;
    margin: 0;
    padding: 0;
}
article,
aside,
figure,
figure img,
figcaption,
hgroup,
footer,
header,
nav,
section,
video,
object {
    display: block;
}
a img {
    border: 0;
}
figure {
    position: relative;
    padding: 0;
    margin: 0;
}
figure img {
    width: 100%;
}

/* GRID */
.container {
    padding-left: 20px;
    padding-right: 20px;
}

.row {
    width: 100%;
    max-width: 1280px;
    min-width: 755px;
    margin: 0 auto;
}

.onecol,
.twocol,
.threecol,
.fourcol,
.fivecol,
.sixcol,
.sevencol,
.eightcol,
.ninecol,
.tencol,
.elevencol {
    margin-right: 3.8%;
    float: left;
    min-height: 1px;
}

.onecol.fullcol,
.twocol.fullcol,
.threecol.fullcol,
.fourcol.fullcol,
.fivecol.fullcol,
.sixcol.fullcol,
.sevencol.fullcol,
.eightcol.fullcol,
.ninecol.fullcol,
.tencol.fullcol,
.elevencol.fullcol {
    margin-right: 0;
}

.row .onecol {
    width: 4.85%;
}

.row .twocol {
    width: 13.45%;
}

.row .threecol {
    width: 22.05%;
}

.row .fourcol {
    width: 30.75%;
}

.row .fivecol {
    width: 39.45%;
}

.row .sixcol {
    width: 48%;
}

.row .sevencol {
    width: 56.75%;
}

.row .eightcol {
    width: 65.4%;
}

.row .ninecol {
    width: 74.05%;
}

.row .tencol {
    width: 82.7%;
}

.row .elevencol {
    width: 91.35%;
}

.row .twelvecol {
    width: 100%;
    float: left;
}

/* full col obviates the need for the 3.8% right margin, the column includes that margin in its width */

.row .onecol.fullcol {
    width: 8.65%;
}

.row .twocol.fullcol {
    width: 17.25%;
}

.row .threecol.fullcol {
    width: 25.85%;
}

.row .fourcol.fullcol {
    width: 34.45%;
}

.row .fivecol.fullcol {
    width: 43.3%;
}

.row .sixcol.fullcol {
    width: 51.8%;
}

.row .sevencol.fullcol {
    width: 60.55%;
}

.row .eightcol.fullcol {
    width: 69.2%;
}

.row .ninecol.fullcol {
    width: 77.85%;
}

.row .tencol.fullcol {
    width: 86.5%;
}

.row .elevencol.fullcol {
    width: 95.15%;
}

.row .twelvecol.fullcol {
    width: 100%;
    float: left;
}

.last {
    margin-right: 0;
}

img,
object,
embed {
    max-width: 100%;
}

img {
    height: auto;
}

.clearfix:before,
.clearfix:after {
    content: "";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    zoom: 1;
}

@media only screen and (max-width: 1024px) {
    body {
        font-size: 1em;
        line-height: 1.5em;
    }
}

@media only screen and (max-width: 1140px) {
    body {
        font-size: 1em;
        line-height: 1.5em;
    }
}

@media handheld, only screen and (max-width: 768px) {
    body {
        font-size: 16px;
        -webkit-text-size-adjust: none;
    }

    .row,
    body {
        width: 100%;
        min-width: 0;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .row .onecol,
    .row .twocol,
    .row .threecol,
    .row .fourcol,
    .row .fivecol,
    .row .sixcol,
    .row .sevencol,
    .row .eightcol,
    .row .ninecol,
    .row .tencol,
    .row .elevencol,
    .row .twelvecol,
    .row .onecol.fullcol,
    .row .twocol.fullcol,
    .row .threecol.fullcol,
    .row .fourcol.fullcol,
    .row .fivecol.fullcol,
    .row .sixcol.fullcol,
    .row .sevencol.fullcol,
    .row .eightcol.fullcol,
    .row .ninecol.fullcol,
    .row .tencol.fullcol,
    .row .elevencol.fullcol {
        width: auto;
        float: none;
        margin-left: 0;
        margin-right: 0;
    }
}

/* GENERAL */
#content {
    position: relative;
    /*box-shadow*/
    -webkit-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.65);
    -moz-box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.65);
    box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.65);
    width: auto;
    margin: 1em auto;
    background: #fff;
    /*transition*/
    -webkit-transition: margin 0.5s, box-shadow 1s 0.5s;
    -moz-transition: margin 0.5s, box-shadow 1s 0.5s;
    -o-transition: margin 0.5s, box-shadow 1s 0.5s;
    transition: margin 0.5s, box-shadow 1s 0.5s;
    z-index: 999;
}

/* HEADER */
#content > header {
    background: #467fcc;
    padding: 1em 0 0;
}

#content > header #logo {
    display: block;
    width: 180px;
    height: 65px;
    margin: 0 auto;
    /*background-size*/
    -webkit-background-size: 100% auto !important;
    -moz-background-size: 100% auto !important;
    -o-background-size: 100% auto !important;
    background-size: 100% auto !important;
    background: url(/img/home-logo.png) no-repeat top left;
    text-indent: -999em;
}

/* MAIN SUPER NAV AND SECTION NAV */
header nav {
    display: block;
    position: relative;
    font: normal 1.2em/1.5 futura-pt, Helvetica, sans-serif;
    text-transform: uppercase;
    background: #4076bf;
    text-align: center;
    z-index: 11;
}

header nav a#navtoggle {
    display: none;
}

header nav ul,
header nav ul li {
    padding: 0;
    margin: 0;
}

header nav ul li {
    display: inline-block;
    background: none;
}

header nav ul li a {
    display: inline-block;
    text-decoration: none;
    color: #fff;
    padding: 0.5em 1em;
}

header nav ul li.current > a,
header nav ul li > a.current {
    font-weight: 700;
}

header nav ul li ul {
    position: absolute;
    left: auto;
    top: -999em;
    text-align: left;
    padding: 1em;
    opacity: 1;
}

header nav ul li:hover ul {
    top: 100%;
    left: auto;
    opacity: 1;
}

/* header nav ul li.community ul, */

header nav ul li.current:hover ul {
    /* 	left: -999*/
    left: -999em;
}

header nav ul li ul li {
    display: inline-block;
    width: 49%;
    background: none;
}

header nav ul li ul li a {
    display: block;
    /*border-radius*/
    -webkit-border-radius: 0.15em;
    -moz-border-radius: 0.15em;
    border-radius: 0.15em;
    margin-bottom: 0.2em;
    font: normal 2em/1 futura-pt-condensed-n4, futura-pt-condensed, Helvetica,
        sans-serif;
    background-color: transparent;
    padding: 0.5em 1em 0.5em 53px;
}

header nav ul li ul li:hover a {
    /*box-shadow*/
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

header nav ul li ul li a:hover,
header nav ul li ul li a.current {
    font-weight: 400;
    /*box-shadow*/
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    /*transition*/
    -webkit-transition: background-color 0.5s, color 0.5s;
    -moz-transition: background-color 0.5s, color 0.5s;
    -o-transition: background-color 0.5s, color 0.5s;
    transition: background-color 0.5s, color 0.5s;
}

/* PAGE SECTION HEADERS */
#section-header {
    position: relative;
    background: #c7c7c7;
    min-height: 5.4em;
}

#section-header h1 {
    position: absolute;
    bottom: 0;
    left: 2em;
    font-size: 2.8em !important;
}

#section-header ul {
    float: right;
    bottom: 0;
    list-style: none;
    width: 55%;
    padding: 1em 0 0.8em;
}

#section-header ul li {
    display: inline-block;
    width: 45%;
}

#section-header ul li a {
    display: block;
    font: normal 1.9em/1 futura-pt-condensed-n4, futura-pt-condensed, Helvetica,
        sans-serif;
    text-decoration: none;
    text-transform: uppercase;
    /*border-radius*/
    -webkit-border-radius: 0.15em;
    -moz-border-radius: 0.15em;
    border-radius: 0.15em;
    margin-bottom: 0.2em;
    color: #405571;
    padding: 0.5em 1em 0.5em 53px;
}

#section-header ul li a:hover {
    background-color: #405571;
    color: #fff;
}

/* CUSTOM ICONS FOR EACH LINK */
header nav ul li ul li a,
header nav ul li ul li a:hover,
#section-header ul li a {
    background-image: url(/img/icon-plant.png);
    background-repeat: no-repeat;
    background-position: 11px 50%;
}

/* Mobile hide/show nav */

header nav ul li ul li.shownav,
#section-header ul li.shownav {
    display: none;
}

/* SECTION LEAD IN BUILDING BLOCKS */
#section-intro {
    padding: 2em;
    -webkit-column-count: 2;
    -webkit-column-gap: 2em;
}

#section-intro p {
    -webkit-column-break-inside: avoid;
}

.section-block {
    width: 50%;
    float: left;
    height: auto;
    min-height: 300px;
    position: relative;
}

.section-block figure {
    max-height: 250px;
    overflow: hidden;
}

.section-block figure figcaption {
    position: absolute;
    top: 1em;
    left: 1em;
}

.section-block figure figcaption h2 a {
    color: #fff;
    text-decoration: none;
    font-size: 1.5em;
    text-shadow: 0 0 0.1em #000;
}

.section-block .info {
    padding: 1em;
    background: #84a0db;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

#pagecontent .section-block .info p.section-block-intro {
    display: none;
    color: #fff;
}

#pagecontent > section.subcontent {
    padding: 2em;
}

#pagecontent > section.subcontent:nth-of-type(odd) {
    background: #456b9f;
    color: #fff !important;
}

#pagecontent > section.subcontent:nth-of-type(even) {
    background: #ebf3ff;
    color: #fff !important;
}

.section-block .info .button {
    float: right;
    margin-right: 1em;
}

.section-block .info button.greenbutton, a.greenbutton{
    color: #fff !important;
}

/* GENERIC ARTICLE PAGES */
article#single {
    padding: 2em;
}

article#single figure img {
    margin-bottom: 0.5em;
}

/* GENERIC TEXT STYLES */
#pagecontent h1 {
    font: normal 2.5em/1.1 futura-pt-condensed-n4, futura-pt-condensed,
        Helvetica, sans-serif;
    text-transform: uppercase;
    margin-bottom: 0.4em;
    color: #405571;
}

#pagecontent h2 {
    font: normal 2em/1.1 futura-pt-condensed-n4, futura-pt-condensed, Helvetica,
        sans-serif;
    text-transform: uppercase;
    margin-bottom: 0.4em;
    color: #405571;
}

#pagecontent h3 {
    font: normal 1.25em/1.3 museo-sans, futura-pt-condensed, Helvetica,
        sans-serif;
    margin-bottom: 0.4em;
    font-weight: 700;
}

#pagecontent p {
    font: normal 1.1em/1.7 museo-sans, Helvetica, sans-serif;
    margin-bottom: 1em;
    color: #353535;
}

#pagecontent p a,
#pagecontent li a {
    color: #4076bf;
}

#pagecontent ul {
    font: normal 1.1em/1.7 museo-sans, Helvetica, sans-serif;
    margin-bottom: 1em;
    padding-left: 2.2em;
}

#pagecontent ul ul {
    font-size: 100%;
}

/* BUTTONS */
button,
a.button {
    display: inline-block;
    text-align: center;
    background: #353535;
    color: #fff;
    padding: 0.6em 1em;
    text-decoration: none;
    text-transform: uppercase;
    /*border-radius*/
    -webkit-border-radius: 0.25em;
    -moz-border-radius: 0.25em;
    border-radius: 0.25em;
    font: 300 1.1em/1 futura-pt, Helvetica, sans-serif;
}

#pagecontent button.bluebutton,
#pagecontent a.bluebutton {
    background: #4076bf;
    color: #fff;
}

button.bluebutton:hover,
a.bluebutton:hover {
    background: #2a5285;
    color: #fff;
}

button.whitebutton,
a.whitebutton {
    background: #fff;
    color: #4076bf;
}

button.whitebutton:hover,
a.whitebutton:hover {
    background: #4076bf;
    color: #fff;
}

button.greenbutton,
a.greenbutton {
    background: #07a807;
    color: #fff;
}

button.greenbutton:hover,
a.greenbutton:hover {
    background: #107010;
    color: #fff;
}

/* FOOTER-RELATED */
footer {
    padding: 3em 0;
    text-align: center;
    font: 300 1.4em/1.5 futura-pt, Helvetica, sans-serif;
    text-transform: uppercase;
}

footer p,
footer a {
    color: #79befe;
    text-decoration: none;
}

footer a:hover {
    text-decoration: underline;
}

/* HOME PAGE SPECIFIC */
/** home header area */
header#homeheader {
    padding: 0;
    height: auto;
    position: relative;
}

header#homeheader #logo {
    display: block;
    /* width:250px; */
    width: 19.5%;
    height: 136px;
    background: url(/img/home-logo.png) no-repeat top left;
    text-indent: -999em;
    position: absolute;
    /* top:60px; */
    top: 17.5%;
    left: 50px;
    z-index: 10;
}

// -- Header Slider

#homeslides {
    width: 100%;
    height: 100%;
    z-index: 9;
    overflow: hidden;
    margin-bottom: 0;

    .slick-slide {
        &:before {
            background-color: transparent !important;
            opacity: 0;
        }

        .slide-image {
            opacity: 1;
        }
    }

    li {
        display: block;
        width: 100%;
        height: 307px;

        figure {
            position: relative;
            width: 100%;

            img {
                width: 100%;
            }

            figcaption {
                position: absolute;
                bottom: 2em;
                right: 0;
                background: #fff;
                padding: 0.25em 2em;
            }
        }
    }
}

/** home the project section **/
#home-theproject {
    position: relative;
    overflow: hidden;
    padding: 8em 2em;
    /*transition*/
    -webkit-transition: background-position 0.5s;
    -moz-transition: background-position 0.5s;
    -o-transition: background-position 0.5s;
    transition: background-position 0.5s;
}

#home-theproject article {
    position: relative;
    z-index: 9;
    /* 	-webkit-transform:translate(-100%,0); */
    /*transition*/
    -webkit-transition: -webkit-transform 0.5s;
    -moz-transition: -moz-transform 0.5s;
    -o-transition: -o-transform 0.5s;
    transition: transform 0.5s;
}

#home-theproject figure {
    z-index: 8;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 700px;
    background: url(/img/home-theproject.png) 100% -30px no-repeat #fff;
}

/* Plant Tour stuff has moved to the more specific section below */
/** home the plant section **/
#home-theplant {
    padding: 2em;
    /* 	background:url(/img/home-theplant.jpg) -10% 45% no-repeat #405571; */
    background: #405571;
}

#home-theplant h1,
#home-theplant h2,
#home-theplant p {
    color: #fff;
}

#home-theplant-production figure {
    background: #224269;
    padding: 1em;
    /*border-radius*/
    -webkit-border-radius: 0.3em;
    -moz-border-radius: 0.3em;
    border-radius: 0.3em;
    /*box-shadow*/
    -webkit-box-shadow: 0 0 0.5em #000;
    -moz-box-shadow: 0 0 0.5em #000;
    box-shadow: 0 0 0.5em #000;
    margin-top: 15%;
}

#home-theplant-production img {
    width: auto;
    float: right;
    margin-left: 2em;
}

#home-theproject div#plant-tour {
    float: right;
    position: relative;
    z-index: 8;
    width: 846px;
    height: 647px;
    border-width: 1px 0;
}

#home-theproject #plant-tour a.tour-link#link-step1,
#home-theproject #plant-tour a.tour-hotspot#hotspot-step1 {
    top: 340px;
    right: 780px;
}

#home-theproject #plant-tour a.tour-link#link-step2,
#home-theproject #plant-tour a.tour-hotspot#hotspot-step2 {
    top: 215px;
    right: 712px;
}

#home-theproject #plant-tour a.tour-link#link-step3,
#home-theproject #plant-tour a.tour-hotspot#hotspot-step3 {
    top: 296px;
    right: 616px;
}

#home-theproject #plant-tour a.tour-link#link-step6,
#home-theproject #plant-tour a.tour-hotspot#hotspot-step6 {
    top: 240px;
    right: 453px;
}

#home-theproject #plant-tour a.tour-link#link-step7,
#home-theproject #plant-tour a.tour-hotspot#hotspot-step7 {
    top: 156px;
    right: 247px;
}

#home-theproject #plant-tour a.tour-link#link-step12,
#home-theproject #plant-tour a.tour-hotspot#hotspot-step12 {
    top: 408px;
    right: 583px;
}

#home-theproject #plant-tour a.tour-link#link-step17,
#home-theproject #plant-tour a.tour-hotspot#hotspot-step17 {
    top: 302px;
    right: 251px;
}

#home-theproject #plant-tour a.tour-link#link-step21,
#home-theproject #plant-tour a.tour-hotspot#hotspot-step21 {
    top: 278px;
    right: 121px;
}

#home-theproject #plant-tour li div#tour-step1 {
    top: 145px;
    left: 100px;
}

#home-theproject #plant-tour li div#tour-step1.current {
    top: 145px;
    left: 70px;
}

#home-theproject #plant-tour li div#tour-step2 {
    top: 20px;
    left: 170px;
}

#home-theproject #plant-tour li div#tour-step2.current {
    top: 20px;
    left: 140px;
}

#home-theproject #plant-tour li div#tour-step3 {
    top: 20px;
    left: 270px;
}

#home-theproject #plant-tour li div#tour-step3.current {
    top: 20px;
    left: 230px;
}

#home-theproject #plant-tour li div#tour-step6 {
    top: 40px;
    right: 535px;
}

#home-theproject #plant-tour li div#tour-step6.current {
    top: 40px;
    right: 505px;
}

#home-theproject #plant-tour li div#tour-step7 {
    top: 40px;
    right: 330px;
}

#home-theproject #plant-tour li div#tour-step7.current {
    top: 40px;
    right: 300px;
}

#home-theproject #plant-tour li div#tour-step12 {
    top: 110px;
    right: 200px;
}

#home-theproject #plant-tour li div#tour-step12.current {
    top: 110px;
    right: 220px;
}

#home-theproject #plant-tour li div#tour-step17 {
    top: 140px;
    right: 270px;
}

#home-theproject #plant-tour li div#tour-step17.current {
    top: 140px;
    right: 310px;
}

#home-theproject #plant-tour li div#tour-step21 {
    top: 190px;
    right: 210px;
}

#home-theproject #plant-tour li div#tour-step21.current {
    top: 190px;
    right: 190px;
}

ul#home-theplant-capacity {
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: 7.5%;
}

ul#home-theplant-capacity li {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    display: block;
    width: 85%;
}

ul#home-theplant-capacity li strong {
    font-size: 2em;
}

#home-theplant-capacity img {
    width: auto;
    margin: 0 auto;
}

/** home envrionment section **/
#home-environment-news {
    background: #242d38;
}

#home-environment {
    background: url(/img/home-environment.jpg) 100% 100% no-repeat #78c978;
    height: 100%;
}

#home-environment article {
    padding: 2em;
}

#home-environment h1 {
    color: #0e4e0e;
}

#home-environment p {
    color: #093b09;
}

#home-environment ul {
    list-style-type: none;
    margin-left: -30px;
}

#home-environment a {
    color: #fff !important;
    margin: 5px 5px 5px 0;
    text-align: left;
}

#home-photogallery {
    background: url(/img/home-photogallery.jpg) #242d38;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
    background-size: cover;
    display: flex;
}

#home-photogallery article {
    padding: 2em;
    min-height: 237px;
    box-sizing: content-box;
}

#home-photogallery h1 {
    color: #ffffff;
}

#home-photogallery p {
    color: #ffffff;
}

/* home news section */
#home-news {
    background: url(/img/home-news.png) -18px -62px no-repeat #242d38;
}

#home-news ol#home-news-articles {
    list-style: none;
}

#home-news ol#home-news-articles li {
    display: block;
    width: 90%;
    padding: 5%;
}

#home-news h1 a,
#home-news p {
    color: #fff;
    text-decoration: none;
}

#home-news h1 a:hover {
    text-decoration: underline;
}

#home-news-articles header p {
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 0.9em;
}

#home-news nav {
    text-align: center;
    margin-bottom: 2em;
}

#home-news nav a {
    margin: 0 -3px;
    z-index: 10;
    position: relative;
}

#home-news nav a.home-news-article-button {
    display: inline-block;
    text-align: center;
    background: #1c4679;
    color: #fff;
    padding: 0.6em 1em;
    text-decoration: none;
    text-transform: uppercase;
    /*border-radius*/
    -webkit-border-radius: 0.25em;
    -moz-border-radius: 0.25em;
    border-radius: 0.25em;
    font: 300 1.1em/1 futura-pt, Helvetica, sans-serif;
    z-index: 9;
}

#home-news nav a.home-news-article-button.prev {
    padding-left: 0.8em;
}

#home-news nav a.home-news-article-button.next {
    padding-right: 0.8em;
}

/* NEWS SECTION */
#latest-news {
    background: #46515e;
    padding: 2em;
}

#latest-news > h2 {
    color: #fff !important;
}

#latest-news ol {
    list-style: none;
}

#latest-news ol li {
    background: #a8afb9;
    margin-bottom: 1em;
}

#latest-news ol li article {
    padding: 1em;
}

#latest-news h1 a,
#latest-news p {
    color: #242d38;
    text-decoration: none;
}

#latest-news h1 a:hover {
    text-decoration: underline;
}

#latest-news header p {
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: 0.9em;
}

#news-media {
    padding: 2em;
    background: #9badc7;
}

#news-media div {
    text-align: center;
}

#news-media h1,
#news-media p {
    text-align: left;
}

#news-media-photos img,
#news-media-videos img {
    margin-bottom: 1em;
}

#news-enquiries {
    padding: 2em;
    background: #28384c;
}

#news-enquiries h1,
#news-enquiries p,
#news-enquiries a {
    color: #fff !important;
}

.archive {
    list-style: none;
    margin: 0;
    padding: 0 !important;
}

.archive li {
    margin-bottom: 1em;
    font-size: 0.9em;
}

#pagecontent .archive .date {
    font-size: 90%;
    color: #999;
}

#pagecontent .archive p {
    margin-bottom: 0;
}

/*
#news-media-photos {
	background:#192a3f;
}

#news-media-videos {
	background:#08182c;
}

#news-media-pubs {
	background: #010b17;
}
*/

/* PHOTOS + VIDEOS AND BEARS OH MY */

#photos-content {
    background: #192a3f;
}

#videos-content {
    background: #08182c;
}

#photos-content,
#videos-content {
    padding: 1em;
}

#pagecontent.news #photos-content h1,
#pagecontent.news #photos-content h2,
#pagecontent.news #videos-content h1,
#pagecontent.news #videos-content h2 {
    color: #fff;
}

#videos-content iframe {
    margin-bottom: 2em;
}

#gallerymain {
}

#gallerymain.loading {
    background: yellow;
    min-height: 300px;
    background: url(/img/loading.gif) no-repeat bottom center;
}

ul#gallery {
    margin: 0;
    padding: 0;
}

ul#gallery li {
    width: 45%;
    float: left;
    list-style: none;
    padding: 1em 1%;
    background: #fff;
    box-shadow: 0 0.1em 0.4em #505050;
    margin: 0 1% 1em 1%;
    text-align: center;
    -webkit-transition: all 0.5s;
    display: none;
}

ul#gallery li img {
    margin-bottom: 0.5em;
}

#gallerylist h2,
#videolist h2 {
    line-height: 33px;
}

#gallerylist ul,
#videolist ul {
    padding: 0;
    margin: 0;
    overflow: scroll;
    max-height: 600px;
}

#gallerylist ul li,
#videolist ul li {
    list-style: none;
}

#gallerylist ul li a,
#videolist ul li a {
    display: block;
    text-decoration: none;
    color: #747474;
    border: 1px solid #939fc3;
    border-bottom: 0;
    padding: 0.5em;
    background: #fff;
}

#gallerylist ul li a:hover,
#gallerylist ul li a.current,
#videolist ul li a:hover,
#videolist ul li a.current {
    background: #efefef;
}

#gallerylist ul li:last-child a,
#videolist ul li:last-child a {
    border-bottom: 1px solid #939fc3;
}

/* SEAWATER QUALITY MONITORING */

#seawater {
    border: 1px solid #ddd;
    margin-bottom: 1.5em;
}

#not-available {
    display: none;
}

#seawater tr th:first-child,
#seawater tr td:first-child {
    border-left: 0 none;
}

#seawater th {
    background-color: #1c782b;
    border-left: 1px solid #fff;
    padding: 5px;
    color: #fff;
}

#seawater td {
    padding: 5px;
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
}

/* PUBLICATIONS */

#publications-content {
    padding: 2em;

    nav ul {
        padding: 0;
        margin: 0 0 1.5em;
        overflow: hidden;

        li {
            list-style: none;

            &:last-child {
                a {
                    border-bottom: 1px solid #939fc3;
                }
            }
        
            a {
                display: block;
                text-decoration: none;
                color: #747474;
                border: 1px solid #939fc3;
                border-bottom: 0;
                padding: 0.5em;
                background: #fff;
        
                &:hover {
                    background: #efefef;
                }
            }
        }
    }
}

#pagecontent .sitemap ul {
    margin-bottom: 0;
}

/* INTERACTIVE STUFF */
/* PLANT TOUR (home page and /project/plant page - desal process */
/* NOTE: home page section layout is above, under home page */
#plant-design {
    background: #456b9f;
    padding: 2em;
}

#plant-design article h1,
#plant-design article h2,
#plant-design article p,
#plant-design article p a {
    color: #fff !important;
}

#plant-design-map {
    position: relative;
    height: 100%;
    height: 600px;
    overflow: hidden;
    margin-bottom: 1em;
    background: #434343;
    box-shadow: 0 0 0.6em #1e1e1e inset;
}

#plant-design-map #diagram {
    position: absolute;
    top: 0;
    left: 0;
    width: 1600px;
    height: 1274px;
    background: url(/img/plant-design-map.jpg) no-repeat top left;
    cursor: move;
    /*box-shadow*/
    -webkit-box-shadow: 0 0 0.6em #000;
    -moz-box-shadow: 0 0 0.6em #000;
    box-shadow: 0 0 0.6em #000;
}

#plant-design-map ol {
    position: relative;
}

#plant-design-map ol li {
    position: absolute;
    list-style: none;
    z-index: 8;
    padding: 1em;
    cursor: pointer !important;
}

#plant-design-map ol li.current {
    z-index: 9;
    padding: 0;
}

#plant-design-map ol li a {
    display: block;
    width: 1.5em;
    height: 1.5em;
    background: url(/img/yellow-dot.png);
    text-indent: -999em;
    cursor: pointer;
    /*border-radius*/
    -webkit-border-radius: 1.5em;
    -moz-border-radius: 1.5em;
    border-radius: 1.5em;
    border-color: transparent;
    z-index: 8;
}

#plant-design-map ol li a.current {
    width: auto;
    height: auto;
    max-width: 180px;
    text-indent: 0;
    padding: 1em;
    /*border-radius*/
    -webkit-border-radius: 0.5em;
    -moz-border-radius: 0.5em;
    border-radius: 0.5em;
    cursor: pointer;
    border: none;
    z-index: 9;
}

#plant-design-map ol li a {
    color: inherit;
    text-decoration: none;
}

/* SITE LOCATIONS */
#plant-design-map ol li#accessroad {
    top: 180px;
    left: 370px;
}

#plant-design-map ol li#energy {
    top: 380px;
    left: 570px;
}

#plant-design-map ol li#height {
    top: 420px;
    left: 620px;
}

#plant-design-map ol li#dune {
    top: 550px;
    left: 850px;
}

#plant-design-map ol li#beach {
    top: 890px;
    left: 540px;
}

#plant-design-map ol li#roof {
    top: 385px;
    left: 675px;
}

#plant-design-map ol li#ponds {
    top: 450px;
    left: 750px;
}

#plant-design-map ol li#vegetation {
    top: 525px;
    left: 1050px;
}

#the-desalination-process {
    position: relative;
    overflow: hidden;
    /*transition*/
    -webkit-transition: background-position 0.5s;
    -moz-transition: background-position 0.5s;
    -o-transition: background-position 0.5s;
    transition: background-position 0.5s;
    background: #ebf3ff;
    padding: 0 2em;
}

#the-desalination-process article {
    position: relative;
    z-index: 9;
    padding: 5em 0 0;
}

#the-desalination-process figure {
    display: block;
    height: auto;
}

#the-desalination-process div#plant-tour {
    position: relative;
    z-index: 8;
    width: 846px;
    height: 647px;
    border-width: 1px 0;
    background: url(/img/home-theproject.png) 0 0 no-repeat;
}

#plant-tour a.tour-hotspot {
    text-indent: -999em;
    position: absolute;
    width: 40px;
    height: 40px;
    border: 4px solid #4e80ff;
    /*border-radius*/
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 40px;
    z-index: 8;
}

#plant-tour a.tour-link {
    text-indent: -999em;
    position: absolute;
    width: 32px;
    height: 32px;
    border: 4px solid transparent;
    /*border-radius*/
    -webkit-border-radius: 40px;
    -moz-border-radius: 40px;
    border-radius: 40px;
    background: url(/img/blue-dot.png);
    margin: 4px;
    z-index: 7;
}

#plant-tour a.tour-hotspot.current {
    border-color: #b61b1b;
}

#plant-tour a.tour-link.current {
    background: url(/img/red-dot.png);
}

#plant-tour a.tour-hotspot {
    /*animation*/
    -webkit-animation: hotspot 1.5s infinite;
    -moz-animation: hotspot 1.5s infinite;
    -ms-animation: hotspot 1.5s infinite;
    -o-animation: hotspot 1.5s infinite;
    animation: hotspot 1.5s infinite;
}

#plant-tour ol {
    list-style: none;
}

#plant-tour li div {
    opacity: 1;
    position: absolute;
    visibility: visible;
    width: 350px;
    background: #3491e7;
    /*box-shadow*/
    -webkit-box-shadow: 0 0.05em 0.4em rgba(0, 0, 0, 0.65);
    -moz-box-shadow: 0 0.05em 0.4em rgba(0, 0, 0, 0.65);
    box-shadow: 0 0.05em 0.4em rgba(0, 0, 0, 0.65);
    /*border-radius*/
    -webkit-border-radius: 0 0 0.4em 0.4em;
    -moz-border-radius: 0 0 0.4em 0.4em;
    border-radius: 0 0 0.4em 0.4em;
    /*transition*/
    -webkit-transition: all 0.5s, left 0.5s, right 0.5s;
    -moz-transition: all 0.5s, left 0.5s, right 0.5s;
    -o-transition: all 0.5s, left 0.5s, right 0.5s;
    transition: all 0.5s, left 0.5s, right 0.5s;
    z-index: 30;
}

#plant-tour li div img {
    margin-bottom: 0.6em;
}

#plant-tour li div h2,
#plant-tour li div p {
    color: #fff !important;
    padding: 0 10px;
    margin-bottom: 0;
    line-height: 1.2;
}

#plant-tour li div a.close {
    position: absolute;
    top: -0.5em;
    left: -0.5em;
    width: 1em;
    height: 1em;
    display: inline-block;
    text-align: center;
    padding: 0.5em;
    text-decoration: none;
    text-transform: uppercase;
    border: 1px solid #000;
    /*border-radius*/
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
    font: 300 1.1em/1 Helvetica, sans-serif;
    background: #000;
    color: #fff;
}

#plant-tour li div a.button {
    float: right;
    margin: 1em 1em 1em 0;
}

/* HOTSPOTS AND POPUP BOXES */
/* HOT SPOTS */
/* Chrome and such have a problem with clicking on things that are animating opacity (I think) so there's now a blue dot (link) and a pulsing border (hotspot) for each Point of Interest on the diagram(me) */
#plant-tour a.tour-link#link-step1,
#plant-tour a.tour-hotspot#hotspot-step1 {
    top: 370px;
    right: 780px;
    /*animation-delay*/
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -ms-animation-delay: 0s;
    -o-animation-delay: 0s;
    animation-delay: 0s;
}

#plant-tour a.tour-link#link-step2,
#plant-tour a.tour-hotspot#hotspot-step2 {
    top: 245px;
    right: 712px;
    /*animation-delay*/
    -webkit-animation-delay: 0.25s;
    -moz-animation-delay: 0.25s;
    -ms-animation-delay: 0.25s;
    -o-animation-delay: 0.25s;
    animation-delay: 0.25s;
}

#plant-tour a.tour-link#link-step3,
#plant-tour a.tour-hotspot#hotspot-step3 {
    top: 326px;
    right: 616px;
    /*animation-delay*/
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    -ms-animation-delay: 0.5s;
    -o-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

#plant-tour a.tour-link#link-step6,
#plant-tour a.tour-hotspot#hotspot-step6 {
    top: 270px;
    right: 453px;
    /*animation-delay*/
    -webkit-animation-delay: 0.75s;
    -moz-animation-delay: 0.75s;
    -ms-animation-delay: 0.75s;
    -o-animation-delay: 0.75s;
    animation-delay: 0.75s;
}

#plant-tour a.tour-link#link-step7,
#plant-tour a.tour-hotspot#hotspot-step7 {
    top: 186px;
    right: 247px;
    /*animation-delay*/
    -webkit-animation-delay: 0s;
    -moz-animation-delay: 0s;
    -ms-animation-delay: 0s;
    -o-animation-delay: 0s;
    animation-delay: 0s;
}

#plant-tour a.tour-link#link-step12,
#plant-tour a.tour-hotspot#hotspot-step12 {
    top: 438px;
    right: 583px;
    /*animation-delay*/
    -webkit-animation-delay: 0.25s;
    -moz-animation-delay: 0.25s;
    -ms-animation-delay: 0.25s;
    -o-animation-delay: 0.25s;
    animation-delay: 0.25s;
}

#plant-tour a.tour-link#link-step17,
#plant-tour a.tour-hotspot#hotspot-step17 {
    top: 332px;
    right: 251px;
    /*animation-delay*/
    -webkit-animation-delay: 0.5s;
    -moz-animation-delay: 0.5s;
    -ms-animation-delay: 0.5s;
    -o-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

#plant-tour a.tour-link#link-step21,
#plant-tour a.tour-hotspot#hotspot-step21 {
    top: 308px;
    right: 121px;
    /*animation-delay*/
    -webkit-animation-delay: 0.75s;
    -moz-animation-delay: 0.75s;
    -ms-animation-delay: 0.75s;
    -o-animation-delay: 0.75s;
    animation-delay: 0.75s;
}

#plant-tour li div {
    opacity: 0;
    z-index: -1;
}

#plant-tour li div#tour-step1 {
    top: 175px;
    left: 100px;
}

#plant-tour li div#tour-step1.current {
    top: 175px;
    left: 70px;
}

#plant-tour li div#tour-step2 {
    top: 50px;
    left: 170px;
}

#plant-tour li div#tour-step2.current {
    top: 50px;
    left: 140px;
}

#plant-tour li div#tour-step3 {
    top: 50px;
    left: 270px;
}

#plant-tour li div#tour-step3.current {
    top: 50px;
    left: 230px;
}

#plant-tour li div#tour-step6 {
    top: 70px;
    right: 535px;
}

#plant-tour li div#tour-step6.current {
    top: 70px;
    right: 505px;
}

#plant-tour li div#tour-step7 {
    top: 70px;
    right: 330px;
}

#plant-tour li div#tour-step7.current {
    top: 70px;
    right: 300px;
}

#plant-tour li div#tour-step12 {
    top: 340px;
    right: 200px;
}

#plant-tour li div#tour-step12.current {
    top: 340px;
    right: 220px;
}

#plant-tour li div#tour-step17 {
    top: 170px;
    right: 270px;
}

#plant-tour li div#tour-step17.current {
    top: 170px;
    right: 310px;
}

#plant-tour li div#tour-step21 {
    top: 230px;
    right: 210px;
}

#plant-tour li div#tour-step21.current {
    top: 230px;
    right: 190px;
}

#plant-tour li div.current {
    opacity: 1;
    z-index: 10;
    visibility: visible;
    /*transition*/
    -webkit-transition: opacity 0.5s, left 0.5s, right 0.5s;
    -moz-transition: opacity 0.5s, left 0.5s, right 0.5s;
    -o-transition: opacity 0.5s, left 0.5s, right 0.5s;
    transition: opacity 0.5s, left 0.5s, right 0.5s;
}

/* -- FORM -- */

form h3 {
    margin-top: 1.5em;
}

form fieldset.captchacontainer {
    margin-top: 1.5em;
}

form .buttons button {
    margin-left: 0;
    margin-right: 1em;
}

#pagecontent .conditions {
    font-size: 90%;
}

/* CONTACT FORM BASICS */

form label {
    display: block;
    margin-bottom: 0.3em;
    margin-top: 1.5em; /*so the spacing is nice for the error messages*/
}

form input,
form select,
form textarea {
    display: block;
    width: 80%;
    border: 1px solid #000;
    padding: 0.75em 0.75em;
}

form input:focus,
form textarea:focus {
    /* when the form element is selected */
    border-color: blue;
}

form textarea {
    height: 100px;
}

form button {
    width: 100px;
    margin: 2em auto;
    margin-left: 214px;
    border: 0 none;
}

form select {
    margin: 7px 0;
}

form fieldset.selects select {
    display: inline-block;
}

form fieldset.radiochecks {
    margin-left: 20px;
    width: 100%;
}

form fieldset.radiochecks label.error {
    color: #d94646;
}

form fieldset.radiochecks input,
form fieldset.radiochecks label {
    display: inline-block;
    width: auto;
    float: left;
    line-height: 24px;
    margin-top: 0;
}

form fieldset.radiochecks input[type="radio"] {
    clear: left;
    margin: 5px 1em 6px 3px;
    padding: 0 0 0 0;
    /* margin-right: 1em; */
    line-height: 24px;
    border: 0 none;
}

form fieldset.radiochecks input[type="checkbox"] {
    clear: left;
    margin: 7px 1em 5px 3px;
    padding: 0 0 0 0;
    /* margin-right: 1em; */
    line-height: 24px;
    border: 0 none;
}

form fieldset.radiochecks fieldset {
    clear: both;
    margin-left: 2.4em;
}

form fieldset.radiochecks fieldset input,
form fieldset.radiochecks fieldset label {
    float: none;
    display: block;
    width: 80%;
    line-height: 1em;
}

form fieldset.radiochecks fieldset label {
    margin-bottom: 3px;
    width: 100%;
}

/* Errors and smallprint etc. */

#pagecontent form p.error,
#pagecontent form span.error {
    color: #c00;
    width: 300px;
    margin-top: 0.5em;
}

form input.error,
form textarea.error {
    border-color: #c00;
}

form #date_of_birth select {
    width: 6em;
    float: left;
    margin-left: 1em;
}

/* MESSAGES */

.message {
    text-align: center;
    padding: 0.25em 1em;
    border-bottom: 1px solid #ccc;
    font-size: 0.9em;
}

.message p,
.message li {
    margin-bottom: 0;
}

.message.positive {
    border-color: #b1cdbd;
}

.message.positive p {
    color: #2cbf6e;
}

.message.negative {
    border-color: #bf2b2b;
}

.message.negative p {
    color: #bf2b2b;
}

.message + .content {
    padding-top: 0;
}

form#search {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
    width: auto;
    padding: 20px;
}

form#search input,
form#search button {
    border-radius: 10px;
    padding: 5px 15px;
    display: inline-block;
    margin: 0;
    float: none;
    clear: none;
    width: auto;
    border: 1px solid #405571;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.6);
}

form#search button {
    background: #4076bf;
}

.result {
    clear: both;
    padding: 0 0.5em;
    box-sizing: border-box;
}
.result a {
    color: #4076bf;
}

.result a:hover {
    color: #405571;
}

.result .text {
    display: inline-block;
    float: left;
    max-width: 740px;
    width: 90%;
    padding: 0.5em;
    box-sizing: border-box;
}

.result .text p {
    font-size: 0.9em !important;
}

.result i {
    display: inline-block;
    height: 32px;
    width: 32px;
    margin-right: 1em;
    float: left;
    overflow: hidden;
    text-indent: -999em;
    background-image: url("/img/search-icons.png");
    box-sizing: border-box;
}

.result.document i {
    background-position: 0 center;
}

.result.video i {
    background-position: -32px center;
}

.result.photo i {
    background-position: -64px center;
}

.result.link i {
    background-position: -96px center;
}

.result.page i {
    background-position: -128px center;
}

/* SECTION COLOUR STYLES */

/* GENERIC FALLBACK */
header nav ul li:hover > a {
    background: #fff;
    color: #4076bf;
    /* 	box-shadow: 0 0.05em 0.4em rgba(0,0,0,0.65);	 */
}

header nav ul li:hover ul li a {
    background-color: none;
}

header nav ul li ul {
    background-color: #fff;
    box-shadow: 0 0.2em 0.2em rgba(0, 0, 0, 0.65);
}

header nav ul li ul li a {
    color: #4076bf;
}

header nav ul li ul li a:hover,
header nav ul li ul li a.current {
    background-color: #4076bf;
    color: #fff;
}

/* THE PROJECT */
header nav ul li.project:hover > a {
    background-color: #6d99e8;
    color: #fff;
    box-shadow: 0 0.05em 0.4em rgba(0, 0, 0, 0.65);
}

header nav ul li.project ul {
    background-color: #6d99e8;
    box-shadow: 0 0.2em 0.2em rgba(0, 0, 0, 0.65);
}

header nav ul li.project ul li a {
    color: #fff;
}

header nav ul li.project ul li a:hover,
header nav ul li.project ul li a.current {
    background-color: #1a519c;
    color: #fff;
}

#pagecontent.vic-desalination-project #section-header {
    background: #6d99e8 url(/img/section-header-project.jpg) no-repeat -10% 50%;
}

#pagecontent.vic-desalination-project #section-header h1 {
    color: #fff;
    left: 1em;
    bottom: 0.2em;
}

#pagecontent.vic-desalination-project #section-header ul li a {
    color: #fff;
}

#pagecontent.vic-desalination-project #section-header ul li a:hover,
#pagecontent.vic-desalination-project #section-header ul li a.current {
    background-color: #1a519c;
    color: #fff;
}

#pagecontent.vic-desalination-project h1,
#pagecontent.vic-desalination-project h2,
#pagecontent.vic-desalination-project p a {
    color: #1a519c;
}

/* THE ENVIRONNEMENT */
header nav ul li.environment:hover > a {
    background-color: #71b87d;
    color: #fff;
    box-shadow: 0 0.05em 0.4em rgba(0, 0, 0, 0.65);
}

header nav ul li.environment ul {
    background: #71b87d;
}

header nav ul li.environment ul {
    box-shadow: 0 0.2em 0.2em rgba(0, 0, 0, 0.65);
}

header nav ul li.environment ul li a {
    color: #fff;
}

header nav ul li.environment ul li a:hover,
header nav ul li.environment ul li a.current {
    background-color: #1c782b;
    color: #fff;
}

#pagecontent.environment #section-header {
    background: #71b87d url(/img/section-header-environment.jpg) no-repeat -10% 50%;
}

#pagecontent.environment #section-header h1 {
    /* color:#1c782b; */
    color: #fff;
    left: 8.5em;
    bottom: 0.2em;
}

#pagecontent.environment #section-header ul li a {
    color: #fff;
}

#pagecontent.environment #section-header ul li a:hover,
#pagecontent.environment #section-header ul li a.current {
    background-color: #1c782b;
    color: #fff;
}

#pagecontent.environment h1,
#pagecontent.environment h2,
#pagecontent.environment p a {
    color: #1c782b;
}

/* ECOLOGICAL RESERVE */
#pagecontent.ecological-reserve #section-header {
    background: #29893a;
}

#pagecontent.ecological-reserve #section-header h1 {
    color: #fff;
}

#pagecontent.ecological-reserve h1,
#pagecontent.ecological-reserve h2,
#pagecontent.ecological-reserve p a {
    color: #29893a;
}

/* NEWS */
header nav ul li.news:hover > a {
    background-color: #242d38;
    color: #fff;
    box-shadow: 0 0.05em 0.4em rgba(0, 0, 0, 0.65);
}

header nav ul li.news ul {
    background-color: #242d38;
    box-shadow: 0 0.2em 0.2em rgba(0, 0, 0, 0.65);
}

header nav ul li.news ul li a {
    color: #fff;
}

header nav ul li.news ul li a:hover,
header nav ul li.news ul li a.current {
    background-color: #1a519c;
    color: #fff;
}

#pagecontent.news #section-header {
    background: #242d38 url(/img/section-header-news.jpg) no-repeat -10% 50%;
}

#pagecontent.news #section-header h1 {
    color: #fff;
    left: 1em;
    bottom: 0.2em;
}

#pagecontent.news #section-header ul li a {
    color: #fff;
}

#pagecontent.news #section-header ul li a:hover,
#pagecontent.news #section-header ul li a.current {
    background-color: #1a519c;
    color: #fff;
}

#pagecontent.news h1,
#pagecontent.news h2,
#pagecontent.news p a {
    color: #1a519c;
}

/* COMMUNITY */
header nav ul li.community:hover > a {
    background-color: #33599e;
    color: #fff;
    box-shadow: 0 0.05em 0.4em rgba(0, 0, 0, 0.65);
}

header nav ul li.community ul {
    background: #33599e;
}

header nav ul li.community ul {
    box-shadow: 0 0.2em 0.2em rgba(0, 0, 0, 0.65);
}

header nav ul li.community ul li a {
    color: #fff;
}

header nav ul li.community ul li a:hover,
header nav ul li.community ul li a.current {
    background-color: #10366c;
    color: #fff;
}

#pagecontent.community #section-header {
    background: #33599e url("/img/section-header-community.jpg") no-repeat -10% 50%;
}

#pagecontent.community #section-header h1 {
    color: #fff;
    left: 1em;
    bottom: 0.2em;
}

#pagecontent.community #section-header ul li a {
    color: #fff;
}

#pagecontent.community #section-header ul li a:hover,
#pagecontent.community #section-header ul li a.current {
    background-color: #10366c;
    color: #fff;
}

#pagecontent.community h1,
#pagecontent.community h2,
#pagecontent.community p a {
    color: #10366c;
}

/* CORPORATE */
header nav ul li.corporate:hover > a {
    background-color: #0b2e6d;
    color: #fff;
    box-shadow: 0 0.05em 0.4em rgba(0, 0, 0, 0.65);
}

header nav ul li.corporate ul {
    background: #0b2e6d;
}

header nav ul li.corporate ul {
    box-shadow: 0 0.2em 0.2em rgba(0, 0, 0, 0.65);
}

header nav ul li.corporate ul li a {
    color: #fff;
}

header nav ul li.corporate ul li a:hover,
header nav ul li.corporate ul li a.current {
    background-color: #001937;
    color: #fff;
}

#pagecontent.corporate #section-header {
    background: #0b2e6d url("/imgsection-header-corporate.jpg") no-repeat -10% 50%;
}

#pagecontent.corporate #section-header h1 {
    color: #fff;
    left: 1em;
    bottom: 0.2em;
}

#pagecontent.corporate #section-header ul li a {
    color: #fff;
}

#pagecontent.corporate #section-header ul li a:hover,
#pagecontent.corporate #section-header ul li a.current {
    background-color: #001937;
    color: #fff;
}

#pagecontent.corporate h1,
#pagecontent.corporate h2,
#pagecontent.corporate p a {
    color: #001937;
}

/* 1140 - 1280 */

@media only screen and (max-width: 1280px) {
    #content {
        margin: 0 auto 1em auto;
    }

    #home-theproject article {
        /* 	-webkit-transform: translate3d(0, 0,0); */
    }
}

@media only screen and (max-width: 1140px) {
    #home-theproject figure {
        /* 		background-position: 130% 30%; */
    }

    #home-theproject article {
        margin-left: 0;
    }
}

/* < 1024 (iPad Horz) */

@media only screen and (max-width: 1024px) {
    form#search {
        width: 100%;
        position: static;
        text-align: center;
        clear: both;
    }

    header#homeheader #logo {
        width: 200px;
        height: 108px;
        margin-left: -100px;
        top: 10%;
        left: 50%;
    }

    #home-theproject figure {
        /* 		background-position: 190% 30%; */
    }

    #home-theproject article {
        background: #fff;
        background: rgba(255, 255, 255, 0.8);
        padding: 2em;
    }

    #home-theplant article {
        background: #405571;
        background: rgba(64, 85, 112, 0.8);
    }

    #home-environment {
        background-position: 200% 100%;
    }

    #home-environment article {
        background: #78c978;
        background: rgba(121, 202, 121, 0.8);
    }

    #plant-desalprocess {
        padding: 0 2em;
    }

    #plant-desalprocess article {
        display: block;
        width: 100%;
        padding-top: 2em;
    }

    #plant-desalprocess figure {
        top: auto;
        width: 100%;
    }

    #plant-desalprocess div#plant-tour {
        margin: 0 auto;
    }

    #pagecontent.environment #section-header h1 {
        left: 5.5em;
    }
}

/* 850px header adjustments */

@media only screen and (max-width: 850px) {
    #homeheader #logo {
        top: 30px;
    }

    #section-header h1 {
        position: relative;
        left: 0 !important;
        margin-top: 1em;
        text-align: center;
    }

    #section-header ul {
        width: 90%;
        float: none;
        padding: 0 5% !important;
    }

    #section-header ul li {
        width: 49%;
        text-align: center;
    }

    #section-header ul li a {
        background-position: 50% 5px;
        padding: 40px 0 5px 0;
    }
}

/* < 768 (iPad Vert) */

@media only screen and (max-width: 768px) {
    header nav ul li ul {
        display: none;
    }

    header nav ul li:hover > a {
        box-shadow: none !important;
    }

    #pagecontent.environment #section-header {
        background-image: none;
    }

    #home-theproject {
        padding: 0;
    }

    #home-theproject figure {
        /* 		background-position: 0% 30%; */
    }

    #home-theplant {
        padding: 0;
    }

    #home-theplant article {
        padding: 2em;
        clear: both;
    }

    #home-environment {
        padding: 0;
    }

    #home-theplant-production,
    #home-theplant-capacity {
        width: 45%;
        float: left;
        margin: 2em 2% 5em 2%;
    }

    #home-theplant-production figure {
        margin-top: 0;
    }

    #home-theplant-capacity {
    }

    #home-theproject #plant-tour ol {
        display: none !important;
    }

    #plant-design-map {
        height: 450px;
        min-height: 0;
    }

    #the-desalination-process div#plant-tour{
        width: 100%;
        background-size: contain;
        height: 300px;   

        ol{
            display: none;
        }
    }
}

/* BELOW 700 there's a bit of weirdness */

@media only screen and (max-width: 700px) {
    header nav ul li a{
        font-size: 0.9rem;
    }

    #pagecontent {
        h1 {
            font: normal 1.875em/1.1 futura-pt-condensed-n4, futura-pt-condensed, Helvetica, sans-serif;
        }
        p {
            font: normal 0.85em/1.7 museo-sans, Helvetica, sans-serif;
        }

        ul li{
            font: normal 0.85em/1.7 museo-sans, Helvetica, sans-serif;
        }

        button, a.button {
            font: 300 0.85em/1 futura-pt, Helvetica, sans-serif;
        }
    }

    #homeheader #logo {
        width: 30%;
        background-size: 100%;
        top: 1em;
        left: 35%;
        -webkit-transition: top 0.5s, left 0.5s;
    }

    #homeheader figcaption {
        display: none !important;
        visibility: hidden;
    }

    #homeslides li{
        height: 38vw;
    }

    a.bluebutton.first{
        margin-bottom: 1em;
    }

    ul#home-theplant-capacity li{
        width: 100%;
        margin: 0 auto;
    }

    #home-news {
        background-position: -5% 110%;
    }

    #section-intro {
        -webkit-column-count: 1;
    }

    .section-block {
        width: 100%;
        float: none;
    }

    footer {
        padding: 0 0 2em;
        p, a{
            font-size: 1rem;
        }

        a img{
            padding: 1em 0;
        }
    }
}

/* < 568 (iPhone Horz)  */

@media only screen and (max-width: 568px) {
    header#homeheader #logo {
        height: 65px;
        top: 1em;
    }

    #section-header {
        position: relative;
    }

    #section-header ul {
        width: 90%;
        float: none;
        padding: 0 5% !important;
    }

    #section-header ul li {
        width: 100%;
        text-align: left;
        display: none;
    }

    #section-header ul li a {
        background-position: 5px 50%;
        padding: 5px 0 5px 45px;
    }

    #section-header ul li.shownav {
        display: block;
        text-align: center;
    }

    #section-header ul li.shownav a {
        padding: 0 !important;
        margin: 0 !important;
        background: none !important;
        /* background: #fff !important; */
        background-image: url("images/icon-menu.gif") !important;
        width: 30px;
        height: 30px;
        text-indent: -999em;
        display: block;
        position: absolute;
        right: 1em;
        top: 30px;
    }

    #section-header h1 {
        padding: 0 2em;
        font-size: 0.8em;
    }

    #home-environment {
        background-position: 100% 100%;
    }

    #home-theplant {
        background-position: 0 0;
    }

    #home-theplant-production,
    #home-theplant-capacity {
        width: auto;
        float: none;
        margin: 2em 2% 2em 2%;
    }

    #plant-desalprocess #plant-tour-diagram {
        /* 		-webkit-transform: scale3d(.75,.75,1); */
        margin: 0;
        overflow: scroll;
    }

    #plant-desalprocess div#plant-tour {
        margin: 0;
    }

    #plant-design-map {
        height: 300px;
        min-height: 0;
    }

    ul#gallery li {
        width: 96%;
    }
}

/* < 1136 (iPhone retina Horz)  */

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 568px) {
}

/* < 320 (iPhone Vert) */

@media only screen and (max-width: 320px) {
}

/* < 640 (iPhone retina Vert) */

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 320px) {
}


// -- Animation

@keyframes hotspot {
    0% {
        -webkit-transform: scale3d(0.01, 0.01, 0.01);
        opacity: 0;
    }

    75% {
        -webkit-transform: scale3d(1, 1, 1);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale3d(1.5, 1.5, 1.5);
        opacity: 0;
    }
}

@-webkit-keyframes hotspot {
    0% {
        -webkit-transform: scale3d(0.01, 0.01, 0.01);
        opacity: 0;
    }

    75% {
        -webkit-transform: scale3d(1, 1, 1);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale3d(1.5, 1.5, 1.5);
        opacity: 0;
    }
}

@-moz-keyframes hotspot {
    0% {
        -moz-transform: scale3d(0.01, 0.01, 0.01);
        opacity: 0;
    }

    75% {
        -moz-transform: scale3d(1, 1, 1);
        opacity: 1;
    }

    100% {
        -moz-transform: scale3d(1.5, 1.5, 1.5);
        opacity: 0;
    }
}